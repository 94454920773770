
import { defineComponent, ref, onMounted, watch } from 'vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import { PricePlan } from '@/core/data/pricePlan';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import moment from 'moment';

export default defineComponent({
	name: 'priceplan-listing',
	components: {
		Datatable
	},
	setup() {
		const loading = ref(true);
		// const checkedMedia = ref([]);
		const tableHeader = ref([
			{
				key: 'checkbox',
				sortable: false
			},
			{
				name: 'Title',
				key: 'title',
				sortable: true
			},
			{
				name: 'Status',
				key: 'published',
				sortable: true
			},
			{
				name: 'Button Label',
				key: 'buttonLabel',
				sortable: true
			},
			{
				name: 'Plan Type',
				key: 'planType',
				sortable: true
			},
			{
				name: 'Publication Date',
				key: 'publicationDate',
				sortable: true
			},
			{
				name: 'Actions',
				key: 'actions'
			}
		]);

		const tableData = ref<Array<PricePlan>>([]);
		const initData = ref<Array<PricePlan>>([]);

		const getList = () => {
			ApiService.get(`PricePlan`)
				.then(({ data, headers }) => {
					tableData.value.splice(0, tableData.value.length, ...data);
					initData.value.splice(0, tableData.value.length, ...data);
					loading.value = false;
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
					loading.value = false;
				});
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs('Priceplan', []);
			getList();
		});

		const deleteItem = id => {
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				buttonsStyling: false,
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-danger',
					cancelButton: 'btn btn-secondary'
				}
			}).then(result => {
				if (result.isConfirmed) {
					deleteData(id);
				}
			});
		};

		const deleteData = id => {
			ApiService.delete(`PricePlan/${id}`)
				.then(({ data }) => {
					// console.log('data', data);
					getList();
					Swal.fire({
						timer: 1500,
						text: 'Data has been successfully deleted!',
						icon: 'success',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				})
				.catch(({ response }) => {
					console.log('response', response);
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
				});
		};

		const searchItems = () => {
			getList();
		};

		return {
			moment,
			loading,
			tableData,
			tableHeader,
			getList,
			searchItems,
			deleteItem
		};
	}
});
